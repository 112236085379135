





































































import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, Ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { VideoWatch } from '../../services/api/video-watch.js';
import { ICameraChannel, ICameraLocation } from '../../types.js';
import { SNAPSHOT_IMG_URL } from '../../constants/Video';
import Plyr from 'plyr';
import shaka from 'shaka-player';
import router from '../../router/index.js';
import { isMobile } from 'mobile-device-detect';

export default defineComponent({
  name: 'VideoWatcher',
  props: ['cameras', 'id', 'channelId', 'layout'],
  setup(props) {
    const data: {
      cameras?: ICameraLocation,
      player: null | Plyr,
      shaka: any,
    } = reactive({
      cameras: props.cameras,
      player: null,
      shaka: null,
    });

    const player: Ref<HTMLVideoElement | null> = ref(null);
    const video: Ref<number | null> = ref(null);
    const zoom: Ref<number> = ref(1);
    const transformOrigin: Ref<string> = ref('0 0');
    const isMoving: Ref<boolean> = ref(false);
    const isMobileRef: Ref<boolean> = ref(isMobile);
    const minZoom = 1;
    const maxZoom = 4;

    const cameraName = computed(() => data.cameras?.channels.find(c => c.id === video.value)?.name ?? 'Камера');
    const now = computed(() => Date.now());
    const snapshotUrl = SNAPSHOT_IMG_URL;

    const videoZoomClass = computed(() => {
      return {
        transform: `scale(${zoom.value})`,
        transformOrigin: transformOrigin.value,
        cursor: isMoving.value ? 'move' : 'default',
      };
    });

    const initShaka = () => {
      if (!(window as any).shaka_init) {
        shaka.polyfill.installAll();
        if (shaka.Player.isBrowserSupported()) {
          (window as any).shaka_init = true;
        } else {
          (window as any).shaka_init = false;
          alert('Ваш браузер не поддерживает воспроизведение потокового видео. Пожалуйста, обновите браузер');
        }
      }
    };
    const play = (channel: ICameraChannel) => {
      if (data.cameras) {
        const url = data.cameras.channels.length > 2 ? channel.sub_stream_url : channel.stream_url;
        video.value = channel.id;

        data.shaka.unload();
        data.shaka
          .load(url)
          .then(() => {
            if (player.value) {
              player.value.play();
            }
          });
      }
    };

    const playDefault = () => {
      if (data.cameras && data.cameras.channels && props.channelId) {
        const channel = data.cameras.channels.find(c => c.id === Number(props.channelId));
        if (channel) {
          play(channel);

          const div = document.getElementById(`item-${channel.id}`);
          if (div) {
            div.scrollIntoView();
          }
        }
      } else if (data.cameras && data.cameras.channels && data.cameras.channels[0]) {
        play(data.cameras.channels[0]);
      }
    };

    const getCameras = async() => {
      const { videocams } = await VideoWatch.list({
        all: true,
        municipalityId: undefined,
        id: props.id,
      });

      data.cameras = videocams[0];

      playDefault();
    };

    if (!props.cameras) {
      getCameras();
    }

    initShaka();

    onMounted(() => {
      if (player.value) {
        data.shaka = new shaka.Player(player.value);
        data.player = new Plyr(player.value, {
          controls: ['play', 'progress', 'fullscreen'],
        });

        data.player.on('ready', () => {
          playDefault();
        });
      }
    });

    onBeforeUnmount(() => {
      data.shaka.unload();
    });

    const transformOriginCalc = (t: HTMLElement, e: WheelEvent | MouseEvent) => {
      const r = (e.offsetX - t.offsetLeft) / t.offsetWidth * 100;
      const n = (e.offsetY - t.offsetTop) / t.offsetHeight * 100;

      return `${r}% ${n}%`;
    };

    return {
      data,
      now,
      video,
      player,
      play,
      snapshotUrl,
      videoZoomClass,
      cameraName,
      isMobile: isMobileRef,
      zoom(e: WheelEvent) {
        const t = (e.target as Node).parentNode as HTMLElement;
        transformOrigin.value = transformOriginCalc(t, e);

        if (e.deltaY < 0 && zoom.value + 0.1 <= maxZoom) {
          zoom.value += 0.1;
        } else if (e.deltaY > 0 && zoom.value - 0.1 >= minZoom) {
          zoom.value -= 0.1;
        }
      },
      move(e: MouseEvent) {
        if (isMoving.value) {
          const t = (e.target as Node).parentNode as HTMLElement;
          transformOrigin.value = transformOriginCalc(t, e);
        }
      },
      startMove(e: MouseEvent) {
        if (e.button === 2) {
          isMoving.value = true;
        }
      },
      stopMove(e: MouseEvent) {
        if (e.button === 2) {
          isMoving.value = false;
        }
      },
      prevCamera() {
        if (!video.value || !data.cameras) {
          return;
        }

        const index = data.cameras.channels.findIndex(c => c.id === video.value);
        if (index === -1 || !data.cameras.channels[index - 1]) {
          return;
        }

        play(data.cameras.channels[index - 1]);
      },
      nextCamera() {
        if (!video.value || !data.cameras) {
          return;
        }

        const index = data.cameras.channels.findIndex(c => c.id === video.value);
        if (index === -1 || !data.cameras.channels[index + 1]) {
          return;
        }

        play(data.cameras.channels[index + 1]);
      },
      goBack() {
        router.push({
          name: 'video-list',
          params: {
            cameras: data.cameras as any,
            id: props.id,
            layout: props.layout,
          },
        });
      },
      close() {
        router.push({
          name: 'main-map',
        });
      },
    };
  },
});
